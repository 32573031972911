export function parseExternalPointer(identifier) {
    if (identifier.startsWith("#"))
        return { kind: "#", identifier: identifier };
    if (identifier.startsWith("geo:"))
        return { kind: "geo", identifier: identifier };
    if (identifier.startsWith("podcast:guid:"))
        return { kind: "podcast:guid", identifier: identifier };
    if (identifier.startsWith("podcast:item:guid:"))
        return { kind: "podcast:item:guid", identifier: identifier };
    if (identifier.startsWith("podcast:publisher:guid:"))
        return { kind: "podcast:publisher:guid", identifier: identifier };
    if (identifier.startsWith("isan:"))
        return { kind: "isan", identifier: identifier };
    if (identifier.startsWith("doi:"))
        return { kind: "doi", identifier: identifier };
    throw new Error("Failed to parse external identifier");
}
export function getExternalPointerFromTag(tag) {
    return parseExternalPointer(tag[1]);
}
