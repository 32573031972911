export function groupByRelay(pointers, defaultKey) {
    let byRelay = new Map();
    for (const pointer of pointers) {
        let relays = pointer.relays?.length ? pointer.relays : defaultKey ? [defaultKey] : [];
        for (const relay of relays) {
            if (!byRelay.has(relay))
                byRelay.set(relay, [pointer]);
            else
                byRelay.get(relay)?.push(pointer);
        }
    }
    return byRelay;
}
/** Ensures that a message only is requested from each relay once in timeout */
export function removePreviouslyUsedRelays(message, keyFn, cache, timeout = 60_000) {
    if (message.force)
        return message;
    let key = keyFn(message);
    let now = Date.now();
    if (message.relays) {
        // requesting from specific relays
        let relays = message.relays;
        relays = relays.filter((relay) => {
            let relayKey = key + " " + relay;
            let last = cache.get(relayKey);
            if (!last || now >= last + timeout) {
                cache.set(relayKey, now);
                return true;
            }
            else
                return false;
        });
        if (relays.length === 0)
            return null;
        return { ...message, relays };
    }
    else {
        // requesting from default relays
        let last = cache.get(key);
        if (!last || now >= last + timeout) {
            cache.set(key, now);
            return message;
        }
        else
            return null;
    }
}
