import { kinds } from "nostr-tools";
import { getAddressPointerFromATag, getEventPointerFromETag } from "./pointers.js";
import { getOrComputeCachedValue } from "./cache.js";
import { getHiddenTags } from "./index.js";
export const BookmarkPublicSymbol = Symbol.for("bookmark-public");
export const BookmarkHiddenSymbol = Symbol.for("bookmark-hidden");
export function parseBookmarkTags(tags) {
    const notes = tags.filter((t) => t[0] === "e" && t[1]).map(getEventPointerFromETag);
    const articles = tags
        .filter((t) => t[0] === "a" && t[1])
        .map(getAddressPointerFromATag)
        .filter((addr) => addr.kind === kinds.LongFormArticle);
    const hashtags = tags.filter((t) => t[0] === "t" && t[1]).map((t) => t[1]);
    const urls = tags.filter((t) => t[0] === "r" && t[1]).map((t) => t[1]);
    return { notes, articles, hashtags, urls };
}
/** Returns the public bookmarks of the event */
export function getBookmarks(bookmark) {
    return getOrComputeCachedValue(bookmark, BookmarkPublicSymbol, () => parseBookmarkTags(bookmark.tags));
}
/** Returns the bookmarks of the event if its unlocked */
export function getHiddenBookmarks(bookmark) {
    return getOrComputeCachedValue(bookmark, BookmarkHiddenSymbol, () => {
        const tags = getHiddenTags(bookmark);
        return tags && parseBookmarkTags(tags);
    });
}
