import { ReadonlySigner } from "applesauce-signers/signers/readonly-signer";
import { BaseAccount } from "../account.js";
/** An account that cannot sign or encrypt anything */
export class ReadonlyAccount extends BaseAccount {
    static type = "readonly";
    toJSON() {
        return super.saveCommonFields({
            signer: undefined,
        });
    }
    static fromJSON(json) {
        const account = new ReadonlyAccount(json.pubkey, new ReadonlySigner(json.pubkey));
        return super.loadCommonFields(account, json);
    }
    static fromPubkey(pubkey) {
        return new ReadonlyAccount(pubkey, new ReadonlySigner(pubkey));
    }
}
