import { jsx as _jsx } from "react/jsx-runtime";
import { createContext } from "react";
export const QueryStoreContext = createContext(null);
export const EventStoreContext = createContext(null);
/** Provides a EventStore to the component tree */
export function EventStoreProvider({ eventStore, children }) {
    return _jsx(EventStoreContext.Provider, { value: eventStore, children: children });
}
/** Provides a QueryStore and EventStore to the component tree */
export function QueryStoreProvider({ queryStore, children }) {
    return (_jsx(EventStoreProvider, { eventStore: queryStore.store, children: _jsx(QueryStoreContext.Provider, { value: queryStore, children: children }) }));
}
