import { normalizeURL } from "./url.js";
export const SeenRelaysSymbol = Symbol.for("seen-relays");
/** Marks an event as being seen on a relay */
export function addSeenRelay(event, relay) {
    if (!event[SeenRelaysSymbol])
        event[SeenRelaysSymbol] = new Set();
    event[SeenRelaysSymbol].add(relay);
    return event[SeenRelaysSymbol];
}
/** Returns the set of relays this event was seen on */
export function getSeenRelays(event) {
    return event[SeenRelaysSymbol];
}
const WEBSOCKET_URL_CHECK = /^wss?:\/\/([-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}|localhost)\b([-a-zA-Z0-9()@:%_\+.~#?&\/\/=]*)$/;
/** A fast check to make sure relay hints are safe to connect to */
export function isSafeRelayURL(relay) {
    // anything smaller than 8 is not a URL
    return relay.length >= 8 && WEBSOCKET_URL_CHECK.test(relay);
}
/** Merge multiple sets of relays and remove duplicates (ignores invalid URLs) */
export function mergeRelaySets(...sources) {
    const set = new Set();
    for (const src of sources) {
        if (!src)
            continue;
        for (const url of src) {
            try {
                const safe = normalizeURL(url).toString();
                if (safe)
                    set.add(safe);
            }
            catch (error) {
                // failed to parse URL, ignore
            }
        }
    }
    return Array.from(set);
}
