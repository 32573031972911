import { kinds, verifyEvent } from "nostr-tools";
import { SimpleSigner } from "applesauce-signers";
import { createDefer } from "applesauce-core/promise";
import { isHexKey, unixNow } from "applesauce-core/helpers";
import { logger } from "applesauce-core";
import { getPublicKey } from "nostr-tools";
import { nanoid } from "nanoid";
import { isNIP04 } from "../helpers/encryption.js";
export function isErrorResponse(response) {
    return !!response.error;
}
export var Permission;
(function (Permission) {
    Permission["GetPublicKey"] = "get_pubic_key";
    Permission["SignEvent"] = "sign_event";
    Permission["Nip04Encrypt"] = "nip04_encrypt";
    Permission["Nip04Decrypt"] = "nip04_decrypt";
    Permission["Nip44Encrypt"] = "nip44_encrypt";
    Permission["Nip44Decrypt"] = "nip44_decrypt";
})(Permission || (Permission = {}));
export var NostrConnectMethod;
(function (NostrConnectMethod) {
    NostrConnectMethod["Connect"] = "connect";
    NostrConnectMethod["CreateAccount"] = "create_account";
    NostrConnectMethod["GetPublicKey"] = "get_public_key";
    NostrConnectMethod["SignEvent"] = "sign_event";
    NostrConnectMethod["Nip04Encrypt"] = "nip04_encrypt";
    NostrConnectMethod["Nip04Decrypt"] = "nip04_decrypt";
    NostrConnectMethod["Nip44Encrypt"] = "nip44_encrypt";
    NostrConnectMethod["Nip44Decrypt"] = "nip44_decrypt";
})(NostrConnectMethod || (NostrConnectMethod = {}));
async function defaultHandleAuth(url) {
    window.open(url, "auth", "width=400,height=600,resizable=no,status=no,location=no,toolbar=no,menubar=no");
}
export class NostrConnectSigner {
    /** A method that is called when the subscription needs to be updated */
    onSubOpen;
    /** A method called when the subscription should be closed */
    onSubClose;
    /** A method that is called when an event needs to be published */
    onPublishEvent;
    // protected pool: IConnectionPool;
    // protected sub: MultiSubscription;
    log = logger.extend("NostrConnectSigner");
    /** The local client signer */
    signer;
    subscriptionOpen = false;
    /** Whether the signer is connected to the remote signer */
    isConnected = false;
    /** The users pubkey */
    pubkey;
    /** Relays to communicate over */
    relays;
    /** The remote signer pubkey */
    remote;
    /** Client pubkey */
    get clientPubkey() {
        return getPublicKey(this.signer.key);
    }
    onAuth = defaultHandleAuth;
    verifyEvent = verifyEvent;
    /** A secret used when initiating a connection from the client side */
    clientSecret = nanoid(12);
    nip04;
    nip44;
    constructor(opts) {
        this.relays = opts.relays;
        this.pubkey = opts.pubkey;
        this.remote = opts.remote;
        this.onSubOpen = opts.onSubOpen;
        this.onSubClose = opts.onSubClose;
        this.onPublishEvent = opts.onPublishEvent;
        if (opts.onAuth)
            this.onAuth = opts.onAuth;
        this.signer = opts?.signer || new SimpleSigner();
        this.nip04 = {
            encrypt: this.nip04Encrypt.bind(this),
            decrypt: this.nip04Decrypt.bind(this),
        };
        this.nip44 = {
            encrypt: this.nip44Encrypt.bind(this),
            decrypt: this.nip44Decrypt.bind(this),
        };
    }
    /** Open the connection */
    async open() {
        if (this.subscriptionOpen)
            return;
        this.subscriptionOpen = true;
        const pubkey = await this.signer.getPublicKey();
        // Setup subscription
        await this.onSubOpen?.([
            {
                kinds: [kinds.NostrConnect],
                "#p": [pubkey],
            },
        ], this.relays, this.handleEvent.bind(this));
        this.log("Opened", this.relays);
    }
    /** Close the connection */
    async close() {
        this.subscriptionOpen = false;
        this.isConnected = false;
        await this.onSubClose?.();
        this.log("Closed");
    }
    requests = new Map();
    auths = new Set();
    /** Call this method with incoming events */
    async handleEvent(event) {
        if (!this.verifyEvent(event))
            return;
        // ignore the event if its not from the remote signer
        if (this.remote && event.pubkey !== this.remote)
            return;
        try {
            const responseStr = isNIP04(event.content)
                ? await this.signer.nip04.decrypt(event.pubkey, event.content)
                : await this.signer.nip44.decrypt(event.pubkey, event.content);
            const response = JSON.parse(responseStr);
            // handle remote signer connection
            if (!this.remote && (response.result === "ack" || (this.clientSecret && response.result === this.clientSecret))) {
                this.log("Got ack response from", event.pubkey, response.result);
                this.isConnected = true;
                this.remote = event.pubkey;
                this.waitingPromise?.resolve();
                this.waitingPromise = null;
                return;
            }
            if (response.id) {
                const p = this.requests.get(response.id);
                if (!p)
                    return;
                if (response.error) {
                    this.log("Got Error", response.id, response.result, response.error);
                    if (response.result === "auth_url") {
                        if (!this.auths.has(response.id)) {
                            this.auths.add(response.id);
                            if (this.onAuth) {
                                try {
                                    await this.onAuth(response.error);
                                }
                                catch (e) {
                                    p.reject(e);
                                }
                            }
                        }
                    }
                    else
                        p.reject(response);
                }
                else if (response.result) {
                    this.log("Got Response", response.id, response.result);
                    p.resolve(response.result);
                }
            }
        }
        catch (e) { }
    }
    async createRequestEvent(content, target = this.remote, kind = kinds.NostrConnect) {
        if (!target)
            throw new Error("Missing target pubkey");
        return await this.signer.signEvent({
            kind,
            created_at: unixNow(),
            tags: [["p", target]],
            content,
        });
    }
    async makeRequest(method, params, kind = kinds.NostrConnect) {
        // Talk to the remote signer or the users pubkey
        if (!this.remote)
            throw new Error("Missing remote signer pubkey");
        const id = nanoid(8);
        const request = { id, method, params };
        const encrypted = await this.signer.nip44.encrypt(this.remote, JSON.stringify(request));
        const event = await this.createRequestEvent(encrypted, this.remote, kind);
        this.log(`Sending request ${id} (${method}) ${JSON.stringify(params)}`);
        const p = createDefer();
        this.requests.set(id, p);
        await this.onPublishEvent?.(event, this.relays);
        return p;
    }
    /** Connect to remote signer */
    async connect(secret, permissions) {
        // Attempt to connect to the users pubkey if remote note set
        if (!this.remote && this.pubkey)
            this.remote = this.pubkey;
        if (!this.remote)
            throw new Error("Missing remote signer pubkey");
        await this.open();
        try {
            const result = await this.makeRequest(NostrConnectMethod.Connect, [
                this.remote,
                secret || "",
                permissions?.join(",") ?? "",
            ]);
            this.isConnected = true;
            return result;
        }
        catch (e) {
            this.isConnected = false;
            this.close();
            throw e;
        }
    }
    waitingPromise = null;
    /** Wait for a remote signer to connect */
    waitForSigner() {
        if (this.isConnected)
            return Promise.resolve();
        this.open();
        this.waitingPromise = createDefer();
        return this.waitingPromise;
    }
    /** Request to create an account on the remote signer */
    async createAccount(username, domain, email, permissions) {
        if (!this.remote)
            throw new Error("Remote pubkey must be set");
        await this.open();
        try {
            const newPubkey = await this.makeRequest(NostrConnectMethod.CreateAccount, [
                username,
                domain,
                email ?? "",
                permissions?.join(",") ?? "",
            ]);
            // set the users new pubkey
            this.pubkey = newPubkey;
            this.isConnected = true;
            return newPubkey;
        }
        catch (e) {
            this.isConnected = false;
            this.close();
            throw e;
        }
    }
    /** Ensure the signer is connected to the remote signer */
    async requireConnection() {
        if (!this.isConnected)
            await this.connect();
    }
    /** Get the users pubkey */
    async getPublicKey() {
        if (this.pubkey)
            return this.pubkey;
        await this.requireConnection();
        return this.makeRequest(NostrConnectMethod.GetPublicKey, []);
    }
    /** Request to sign an event */
    async signEvent(template) {
        await this.requireConnection();
        const eventString = await this.makeRequest(NostrConnectMethod.SignEvent, [JSON.stringify(template)]);
        const event = JSON.parse(eventString);
        if (!this.verifyEvent(event))
            throw new Error("Invalid event");
        return event;
    }
    // NIP-04
    async nip04Encrypt(pubkey, plaintext) {
        await this.requireConnection();
        return this.makeRequest(NostrConnectMethod.Nip04Encrypt, [pubkey, plaintext]);
    }
    async nip04Decrypt(pubkey, ciphertext) {
        await this.requireConnection();
        const plaintext = await this.makeRequest(NostrConnectMethod.Nip04Decrypt, [pubkey, ciphertext]);
        // NOTE: not sure why this is here, best guess is some signer used to return results as '["plaintext"]'
        if (plaintext.startsWith('["') && plaintext.endsWith('"]'))
            return JSON.parse(plaintext)[0];
        return plaintext;
    }
    // NIP-44
    async nip44Encrypt(pubkey, plaintext) {
        await this.requireConnection();
        return this.makeRequest(NostrConnectMethod.Nip44Encrypt, [pubkey, plaintext]);
    }
    async nip44Decrypt(pubkey, ciphertext) {
        await this.requireConnection();
        const plaintext = await this.makeRequest(NostrConnectMethod.Nip44Decrypt, [pubkey, ciphertext]);
        // NOTE: not sure why this is here, best guess is some signer used to return results as '["plaintext"]'
        if (plaintext.startsWith('["') && plaintext.endsWith('"]'))
            return JSON.parse(plaintext)[0];
        return plaintext;
    }
    /** Returns the nostrconnect:// URI for this signer */
    getNostrConnectURI(metadata) {
        const params = new URLSearchParams();
        params.set("secret", this.clientSecret);
        if (metadata?.name)
            params.set("name", metadata.name);
        if (metadata?.url)
            params.set("url", String(metadata.url));
        if (metadata?.image)
            params.set("image", metadata.image);
        if (metadata?.permissions)
            params.set("perms", metadata.permissions.join(","));
        for (const relay of this.relays)
            params.append("relay", relay);
        const client = getPublicKey(this.signer.key);
        return `nostrconnect://${client}?` + params.toString();
    }
    /** Parses a bunker:// URI */
    static parseBunkerURI(uri) {
        const url = new URL(uri);
        // firefox puts pubkey part in host, chrome puts pubkey in pathname
        const remote = url.host || url.pathname.replace("//", "");
        if (!isHexKey(remote))
            throw new Error("Invalid connection URI");
        const relays = url.searchParams.getAll("relay");
        if (relays.length === 0)
            throw new Error("Missing relays");
        const secret = url.searchParams.get("secret") ?? undefined;
        return { remote, relays, secret };
    }
    /** Builds an array of signing permissions for event kinds */
    static buildSigningPermissions(kinds) {
        return [Permission.GetPublicKey, ...kinds.map((k) => `${Permission.SignEvent}:${k}`)];
    }
    /** Create a {@link NostrConnectSigner} from a bunker:// URI */
    static async fromBunkerURI(uri, options) {
        const { remote, relays, secret } = NostrConnectSigner.parseBunkerURI(uri);
        const client = new NostrConnectSigner({ relays, remote, ...options });
        await client.connect(secret, options.permissions);
        return client;
    }
}
