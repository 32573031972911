/** Creates a controlled promise */
export function createDefer() {
    let _resolve;
    let _reject;
    const promise = new Promise((resolve, reject) => {
        // @ts-ignore
        _resolve = resolve;
        _reject = reject;
    });
    // @ts-ignore
    promise.resolve = _resolve;
    // @ts-ignore
    promise.reject = _reject;
    return promise;
}
