import { COMMENT_KIND, getEventUID } from "../helpers/index.js";
import { isParameterizedReplaceableKind } from "nostr-tools/kinds";
/** Returns all NIP-22 comment replies for the event */
export function CommentsQuery(parent) {
    return {
        key: `${getEventUID(parent)}-comments`,
        run: (events) => {
            const filter = { kinds: [COMMENT_KIND], "#e": [parent.id] };
            if (isParameterizedReplaceableKind(parent.kind))
                filter["#a"] = [getEventUID(parent)];
            return events.timeline(filter);
        },
    };
}
