import { PasswordSigner } from "applesauce-signers/signers/password-signer";
import { BaseAccount } from "../account.js";
export class PasswordAccount extends BaseAccount {
    static type = "ncryptsec";
    get unlocked() {
        return this.signer.unlocked;
    }
    /** called when PasswordAccount.unlock is called without a password */
    static async requestUnlockPassword(_account) {
        throw new Error("Cant unlock PasswordAccount without a password. either pass one in or set PasswordAccount.requestUnlockPassword");
    }
    /**
     * Attempt to unlock the signer with a password
     * @throws
     */
    async unlock(password) {
        password = password || (await PasswordAccount.requestUnlockPassword(this));
        await this.signer.unlock(password);
    }
    toJSON() {
        if (!this.signer.ncryptsec)
            throw new Error("Cant save account without ncryptsec");
        return super.saveCommonFields({
            signer: { ncryptsec: this.signer.ncryptsec },
        });
    }
    static fromJSON(json) {
        const signer = new PasswordSigner();
        signer.ncryptsec = json.signer.ncryptsec;
        const account = new PasswordAccount(json.pubkey, signer);
        return super.loadCommonFields(account, json);
    }
    /** Creates a new PasswordAccount from a ncryptsec string */
    static fromNcryptsec(pubkey, ncryptsec) {
        const signer = new PasswordSigner();
        signer.ncryptsec = ncryptsec;
        return new PasswordAccount(pubkey, signer);
    }
}
