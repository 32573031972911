import { tap, from, filter, map, mergeAll, bufferTime } from "rxjs";
import { createRxOneshotReq } from "rx-nostr";
import { markFromCache } from "applesauce-core/helpers";
import { logger } from "applesauce-core";
import { nanoid } from "nanoid";
import { Loader } from "./loader.js";
import { generatorSequence } from "../operators/generator-sequence.js";
import { consolidateAddressPointers, createFiltersFromAddressPointers } from "../helpers/address-pointer.js";
import { groupByRelay } from "../helpers/pointer.js";
import { distinctRelaysBatch } from "../operators/distinct-relays.js";
/** A generator that tries to load the address pointers from the cache first, then tries the relays */
function* cacheFirstSequence(rxNostr, pointers, log, opts) {
    const id = nanoid(8);
    log = log.extend(id);
    // first attempt, load from cache relays
    if (opts?.cacheRequest) {
        log(`Checking cache`);
        const filters = createFiltersFromAddressPointers(pointers);
        const results = yield opts.cacheRequest(filters).pipe(
        // mark the event as from the cache
        tap((event) => markFromCache(event)), 
        // convert to event packets
        map((e) => ({ event: e, from: "", subId: "user-sets-loader", type: "EVENT" })));
        if (results.length > 0) {
            log(`Loaded ${results.length} events from cache`);
        }
    }
    let byRelay = groupByRelay(pointers, "default");
    // load sets from relays
    yield from(Array.from(byRelay.entries()).map(([relay, pointers]) => {
        let filters = createFiltersFromAddressPointers(pointers);
        let count = 0;
        const req = createRxOneshotReq({ filters, rxReqId: id });
        log(`Requesting from ${relay}`, pointers);
        let sub$;
        // don't specify relay if this is the "default" relay
        if (relay === "default")
            sub$ = rxNostr.use(req);
        else
            sub$ = rxNostr.use(req, { on: { relays: [relay] } });
        return sub$.pipe(tap({
            next: () => count++,
            complete: () => log(`Completed ${relay}, loaded ${count} events`),
        }));
    })).pipe(mergeAll());
}
/** A loader that can be used to load users NIP-51 sets events ( kind >= 30000 < 40000) */
export class UserSetsLoader extends Loader {
    log = logger.extend("UserSetsLoader");
    constructor(rxNostr, opts) {
        let options = opts || {};
        super((source) => source.pipe(
        // load first from cache
        bufferTime(options?.bufferTime ?? 1000), 
        // ignore empty buffers
        filter((buffer) => buffer.length > 0), 
        // only load from each relay once
        distinctRelaysBatch((p) => p.kind + ":" + p.pubkey, options.refreshTimeout ?? 120_000), 
        // deduplicate address pointers
        map(consolidateAddressPointers), 
        // check cache, relays, lookup relays in that order
        generatorSequence((pointers) => cacheFirstSequence(rxNostr, pointers, this.log, options), 
        // there will always be more events, never complete
        false)));
    }
}
