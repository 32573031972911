import { filter, finalize, ReplaySubject, share, startWith, timer } from "rxjs";
import hash_sum from "hash-sum";
import * as Queries from "../queries/index.js";
import { getObservableValue } from "../observable/get-observable-value.js";
export class QueryStore {
    static Queries = Queries;
    store;
    constructor(store) {
        if (!store)
            throw new Error("EventStore required");
        this.store = store;
    }
    /** A directory of all active queries */
    queries = new Map();
    /** How long a query should be kept "warm" while nothing is subscribed to it */
    queryKeepWarmTimeout = 60_000;
    /** Creates a cached query */
    createQuery(queryConstructor, ...args) {
        let observables = this.queries.get(queryConstructor);
        if (!observables) {
            observables = new Map();
            this.queries.set(queryConstructor, observables);
        }
        const key = hash_sum(args);
        let observable = observables.get(key);
        if (!observable) {
            const cleanup = () => {
                if (observables.get(key) === observable)
                    observables.delete(key);
            };
            observable = queryConstructor(...args)
                .run(this.store, this)
                .pipe(
            // always emit undefined so the observable is sync
            startWith(undefined), 
            // remove the observable when its subscribed
            finalize(cleanup), 
            // only create a single observable for all components
            share({ connector: () => new ReplaySubject(1), resetOnComplete: () => timer(this.queryKeepWarmTimeout) }));
            // set debug fields
            Reflect.set(observable, "queryArgs", args);
            observables.set(key, observable);
        }
        return observable;
    }
    /** Creates a query and waits for the next value */
    async executeQuery(queryConstructor, ...args) {
        const query = this.createQuery(queryConstructor, ...args).pipe(filter((v) => v !== undefined));
        return getObservableValue(query);
    }
    /** Creates a SingleEventQuery */
    event(id) {
        return this.createQuery(Queries.SingleEventQuery, id);
    }
    /** Creates a MultipleEventsQuery */
    events(ids) {
        return this.createQuery(Queries.MultipleEventsQuery, ids);
    }
    /** Creates a ReplaceableQuery */
    replaceable(kind, pubkey, d) {
        return this.createQuery(Queries.ReplaceableQuery, kind, pubkey, d);
    }
    /** Creates a ReplaceableSetQuery */
    replaceableSet(pointers) {
        return this.createQuery(Queries.ReplaceableSetQuery, pointers);
    }
    /** Creates a TimelineQuery */
    timeline(filters, keepOldVersions) {
        return this.createQuery(Queries.TimelineQuery, filters, keepOldVersions);
    }
    /** Creates a ProfileQuery */
    profile(pubkey) {
        return this.createQuery(Queries.ProfileQuery, pubkey);
    }
    /** Creates a ReactionsQuery */
    reactions(event) {
        return this.createQuery(Queries.ReactionsQuery, event);
    }
    /** Creates a MailboxesQuery */
    mailboxes(pubkey) {
        return this.createQuery(Queries.MailboxesQuery, pubkey);
    }
    /** Creates a ThreadQuery */
    thread(root) {
        return this.createQuery(Queries.ThreadQuery, root);
    }
}
export { Queries };
