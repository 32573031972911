import { kinds } from "nostr-tools";
import { insertEventIntoDescendingList } from "nostr-tools/utils";
import { isParameterizedReplaceableKind } from "nostr-tools/kinds";
import { defer, distinctUntilChanged, EMPTY, endWith, filter, finalize, from, map, merge, mergeMap, mergeWith, of, repeat, scan, take, takeUntil, tap, } from "rxjs";
import { Database } from "./database.js";
import { getEventUID, getReplaceableIdentifier, getReplaceableUID, getTagValue, isReplaceable, } from "../helpers/event.js";
import { matchFilters } from "../helpers/filter.js";
import { addSeenRelay, getSeenRelays } from "../helpers/relays.js";
import { getDeleteCoordinates, getDeleteIds } from "../helpers/delete.js";
import { claimEvents } from "../observable/claim-events.js";
import { claimLatest } from "../observable/claim-latest.js";
function sortDesc(a, b) {
    return b.created_at - a.created_at;
}
export class EventStore {
    database;
    /** Enable this to keep old versions of replaceable events */
    keepOldVersions = false;
    /** A method used to verify new events before added them */
    verifyEvent;
    constructor() {
        this.database = new Database();
        this.database.onBeforeInsert = (event) => {
            // reject events that are invalid
            if (this.verifyEvent && this.verifyEvent(event) === false)
                throw new Error("Invalid event");
        };
    }
    // delete state
    deletedIds = new Set();
    deletedCoords = new Map();
    checkDeleted(event) {
        if (typeof event === "string")
            return this.deletedIds.has(event);
        else {
            if (this.deletedIds.has(event.id))
                return true;
            if (isParameterizedReplaceableKind(event.kind)) {
                const deleted = this.deletedCoords.get(getEventUID(event));
                if (deleted)
                    return deleted > event.created_at;
            }
            return false;
        }
    }
    // handling delete events
    handleDeleteEvent(deleteEvent) {
        const ids = getDeleteIds(deleteEvent);
        for (const id of ids) {
            this.deletedIds.add(id);
            // remove deleted events in the database
            const event = this.database.getEvent(id);
            if (event)
                this.database.removeEvent(event);
        }
        const coords = getDeleteCoordinates(deleteEvent);
        for (const coord of coords) {
            this.deletedCoords.set(coord, Math.max(this.deletedCoords.get(coord) ?? 0, deleteEvent.created_at));
            // remove deleted events in the database
            const event = this.database.getEvent(coord);
            if (event && event.created_at < deleteEvent.created_at)
                this.database.removeEvent(event);
        }
    }
    /** Copies important metadata from and identical event to another */
    static mergeDuplicateEvent(source, dest) {
        const relays = getSeenRelays(source);
        if (relays) {
            for (const relay of relays)
                addSeenRelay(dest, relay);
        }
    }
    /**
     * Adds an event to the database and update subscriptions
     * @throws
     */
    add(event, fromRelay) {
        if (event.kind === kinds.EventDeletion)
            this.handleDeleteEvent(event);
        // Ignore if the event was deleted
        if (this.checkDeleted(event))
            return event;
        // Insert event into database
        const inserted = this.database.addEvent(event);
        // Copy cached data if its a duplicate event
        if (event !== inserted)
            EventStore.mergeDuplicateEvent(event, inserted);
        // attach relay this event was from
        if (fromRelay)
            addSeenRelay(inserted, fromRelay);
        // remove all old version of the replaceable event
        if (!this.keepOldVersions && isReplaceable(event.kind)) {
            const existing = this.database.getReplaceable(event.kind, event.pubkey, getTagValue(event, "d"));
            if (existing) {
                const older = Array.from(existing).filter((e) => e.created_at < event.created_at);
                for (const old of older)
                    this.database.removeEvent(old);
                // return the newest version of the replaceable event
                // most of the time this will be === event, but not always
                if (existing.length !== older.length)
                    return existing[0];
            }
        }
        return inserted;
    }
    /** Removes an event from the database and updates subscriptions */
    remove(event) {
        return this.database.removeEvent(event);
    }
    /** Removes any event that is not being used by a subscription */
    prune(max) {
        return this.database.prune(max);
    }
    /** Add an event to the store and notifies all subscribes it has updated */
    update(event) {
        return this.database.updateEvent(event);
    }
    /** Get all events matching a filter */
    getAll(filters) {
        return this.database.getForFilters(filters);
    }
    /** Check if the store has an event */
    hasEvent(uid) {
        return this.database.hasEvent(uid);
    }
    getEvent(uid) {
        return this.database.getEvent(uid);
    }
    /** Check if the store has a replaceable event */
    hasReplaceable(kind, pubkey, d) {
        return this.database.hasReplaceable(kind, pubkey, d);
    }
    /** Gets the latest version of a replaceable event */
    getReplaceable(kind, pubkey, d) {
        return this.database.getReplaceable(kind, pubkey, d)?.[0];
    }
    /** Returns all versions of a replaceable event */
    getReplaceableHistory(kind, pubkey, d) {
        return this.database.getReplaceable(kind, pubkey, d);
    }
    /**
     * Creates an observable that streams all events that match the filter and remains open
     * @param filters
     * @param [onlyNew=false] Only subscribe to new events
     */
    filters(filters, onlyNew = false) {
        filters = Array.isArray(filters) ? filters : [filters];
        return merge(
        // merge existing events
        onlyNew ? EMPTY : from(this.getAll(filters)), 
        // subscribe to future events
        this.database.inserted.pipe(filter((e) => matchFilters(filters, e))));
    }
    /** Returns an observable that completes when an event is removed */
    removed(id) {
        const deleted = this.checkDeleted(id);
        if (deleted)
            return EMPTY;
        return this.database.removed.pipe(
        // listen for removed events
        filter((e) => e.id === id), 
        // complete as soon as we find a matching removed event
        take(1), 
        // switch to empty
        mergeMap(() => EMPTY));
    }
    /** Creates an observable that emits when event is updated */
    updated(id) {
        return this.database.updated.pipe(filter((e) => e.id === id));
    }
    /** Creates an observable that subscribes to a single event */
    event(id) {
        return merge(
        // get current event and ignore if there is none
        defer(() => {
            let event = this.getEvent(id);
            return event ? of(event) : EMPTY;
        }), 
        // subscribe to updates
        this.database.inserted.pipe(filter((e) => e.id === id)), 
        // subscribe to updates
        this.updated(id), 
        // emit undefined when deleted
        this.removed(id).pipe(endWith(undefined))).pipe(
        // claim all events
        claimEvents(this.database));
    }
    /** Creates an observable that subscribes to multiple events */
    events(ids) {
        return merge(
        // lazily get existing events
        defer(() => from(ids.map((id) => this.getEvent(id)))), 
        // subscribe to new events
        this.database.inserted.pipe(filter((e) => ids.includes(e.id))), 
        // subscribe to updates
        this.database.updated.pipe(filter((e) => ids.includes(e.id)))).pipe(
        // ignore empty messages
        filter((e) => !!e), 
        // claim all events until cleanup
        claimEvents(this.database), 
        // watch for removed events
        mergeWith(this.database.removed.pipe(filter((e) => ids.includes(e.id)), map((e) => e.id))), 
        // merge all events into a directory
        scan((dir, event) => {
            if (typeof event === "string") {
                // delete event by id
                const clone = { ...dir };
                delete clone[event];
                return clone;
            }
            else {
                // add even to directory
                return { ...dir, [event.id]: event };
            }
        }, {}));
    }
    /** Creates an observable that subscribes to the latest version of a replaceable event */
    replaceable(kind, pubkey, d) {
        let current = undefined;
        return merge(
        // lazily get current event
        defer(() => {
            let event = this.getReplaceable(kind, pubkey, d);
            return event ? of(event) : EMPTY;
        }), 
        // subscribe to new events
        this.database.inserted.pipe(filter((e) => e.pubkey == pubkey && e.kind === kind && (d !== undefined ? getReplaceableIdentifier(e) === d : true)))).pipe(
        // only update if event is newer
        distinctUntilChanged((prev, event) => prev.created_at >= event.created_at), 
        // Hacky way to extract the current event so takeUntil can access it
        tap((event) => (current = event)), 
        // complete when event is removed
        takeUntil(this.database.removed.pipe(filter((e) => e.id === current?.id))), 
        // emit undefined when removed
        endWith(undefined), 
        // keep the observable hot
        repeat(), 
        // claim latest event
        claimLatest(this.database));
    }
    /** Creates an observable that subscribes to the latest version of an array of replaceable events*/
    replaceableSet(pointers) {
        const uids = new Set(pointers.map((p) => getReplaceableUID(p.kind, p.pubkey, p.identifier)));
        return merge(
        // start with existing events
        defer(() => from(pointers.map((p) => this.getReplaceable(p.kind, p.pubkey, p.identifier)))), 
        // subscribe to new events
        this.database.inserted.pipe(filter((e) => isReplaceable(e.kind) && uids.has(getEventUID(e))))).pipe(
        // filter out undefined
        filter((e) => !!e), 
        // claim all events
        claimEvents(this.database), 
        // convert events to add commands
        map((e) => ["add", e]), 
        // watch for removed events
        mergeWith(this.database.removed.pipe(filter((e) => isReplaceable(e.kind) && uids.has(getEventUID(e))), map((e) => ["remove", e]))), 
        // reduce events into directory
        scan((dir, [action, event]) => {
            const uid = getEventUID(event);
            if (action === "add") {
                // add event to dir if its newer
                if (!dir[uid] || dir[uid].created_at < event.created_at)
                    return { ...dir, [uid]: event };
            }
            else if (action === "remove" && dir[uid] === event) {
                // remove event from dir
                let newDir = { ...dir };
                delete newDir[uid];
                return newDir;
            }
            return dir;
        }, {}), 
        // ignore changes that do not modify the directory
        distinctUntilChanged());
    }
    /** Creates an observable that updates with an array of sorted events */
    timeline(filters, keepOldVersions = false) {
        filters = Array.isArray(filters) ? filters : [filters];
        const seen = new Map();
        // get current events
        return defer(() => of(Array.from(this.database.getForFilters(filters)).sort(sortDesc))).pipe(
        // claim existing events
        claimEvents(this.database), 
        // subscribe to newer events
        mergeWith(this.database.inserted.pipe(filter((e) => matchFilters(filters, e)), 
        // claim all new events
        claimEvents(this.database))), 
        // subscribe to delete events
        mergeWith(this.database.removed.pipe(filter((e) => matchFilters(filters, e)), map((e) => e.id))), 
        // build a timeline
        scan((timeline, event) => {
            // filter out removed events from timeline
            if (typeof event === "string")
                return timeline.filter((e) => e.id !== event);
            // initial timeline array
            if (Array.isArray(event)) {
                if (!keepOldVersions) {
                    for (const e of event)
                        if (isReplaceable(e.kind))
                            seen.set(getEventUID(e), e);
                }
                return event;
            }
            // create a new timeline and insert the event into it
            let newTimeline = [...timeline];
            // remove old replaceable events if enabled
            if (!keepOldVersions && isReplaceable(event.kind)) {
                const uid = getEventUID(event);
                const existing = seen.get(uid);
                // if this is an older replaceable event, exit
                if (existing && event.created_at < existing.created_at)
                    return timeline;
                // update latest version
                seen.set(uid, event);
                // remove old event from timeline
                if (existing)
                    newTimeline.slice(newTimeline.indexOf(existing), 1);
            }
            // add event into timeline
            insertEventIntoDescendingList(newTimeline, event);
            return newTimeline;
        }, []), 
        // ignore changes that do not modify the timeline instance
        distinctUntilChanged(), 
        // hacky hack to clear seen on unsubscribe
        finalize(() => seen.clear()));
    }
}
