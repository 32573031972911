import { generateSecretKey, getPublicKey } from "nostr-tools";
import { SimpleSigner } from "applesauce-signers/signers/simple-signer";
import { bytesToHex, hexToBytes } from "@noble/hashes/utils";
import { BaseAccount } from "../account.js";
export class SimpleAccount extends BaseAccount {
    static type = "nsec";
    toJSON() {
        return super.saveCommonFields({
            signer: { key: bytesToHex(this.signer.key) },
        });
    }
    static fromJSON(json) {
        const key = hexToBytes(json.signer.key);
        const account = new SimpleAccount(json.pubkey, new SimpleSigner(key));
        return super.loadCommonFields(account, json);
    }
    static fromKey(key) {
        if (typeof key === "string")
            key = hexToBytes(key);
        const pubkey = getPublicKey(key);
        return new SimpleAccount(pubkey, new SimpleSigner(key));
    }
    static generateNew() {
        const key = generateSecretKey();
        return SimpleAccount.fromKey(key);
    }
}
