import { NostrConnectSigner, SimpleSigner } from "applesauce-signers";
import { bytesToHex, hexToBytes } from "@noble/hashes/utils";
import { BaseAccount } from "../account.js";
/** An account type for NIP-46 signers */
export class NostrConnectAccount extends BaseAccount {
    static type = "nostr-connect";
    toJSON() {
        if (!this.signer.remote)
            throw new Error("Cant save NostrConnectAccount when not initialized");
        return super.saveCommonFields({
            signer: {
                clientKey: bytesToHex(this.signer.signer.key),
                remote: this.signer.remote,
                relays: this.signer.relays,
            },
        });
    }
    /** This is called when NostrConnectAccount.fromJSON needs new connection methods for NostrConnectSigner */
    static createConnectionMethods() {
        throw new Error("Cant create NostrConnectAccount without either passing in connection methods or setting NostrConnectAccount.createConnectionMethods");
    }
    static fromJSON(json, connection) {
        connection = connection || NostrConnectAccount.createConnectionMethods();
        const signer = new NostrConnectSigner({
            ...connection,
            relays: json.signer.relays,
            pubkey: json.pubkey,
            remote: json.signer.remote,
            signer: new SimpleSigner(hexToBytes(json.signer.clientKey)),
        });
        const account = new NostrConnectAccount(json.pubkey, signer);
        return super.loadCommonFields(account, json);
    }
}
