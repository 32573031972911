import { getCoordinateFromAddressPointer } from "applesauce-core/helpers";
import { fillAndTrimTag } from "./tag.js";
/** Returns a tag for an address pointer */
export function createATagFromAddressPointer(pointer) {
    const coordinate = getCoordinateFromAddressPointer(pointer);
    return fillAndTrimTag(["a", coordinate, pointer.relays?.[0]]);
}
/** Returns a tag for an event pointer with a marker*/
export function createETagWithMarkerFromEventPointer(pointer, marker) {
    return fillAndTrimTag(["e", pointer.id, pointer.relays?.[0], marker, pointer.author]);
}
/** Returns a tag for an event pointer without a marker */
export function createETagFromEventPointer(pointer) {
    return fillAndTrimTag(["e", pointer.id, pointer.relays?.[0]]);
}
/** Returns a tag for an profile pointer */
export function createPTagFromProfilePointer(pointer) {
    return fillAndTrimTag(["p", pointer.pubkey, pointer.relays?.[0]]);
}
