import { kinds } from "nostr-tools";
import { map } from "rxjs/operators";
import { getHiddenMutedThings, getMutedThings } from "../helpers/mutes.js";
import { isHiddenTagsLocked } from "../helpers/hidden-tags.js";
export function UserMuteQuery(pubkey) {
    return {
        key: pubkey,
        run: (store) => store.replaceable(kinds.Mutelist, pubkey).pipe(map((event) => event && getMutedThings(event))),
    };
}
export function UserHiddenMuteQuery(pubkey) {
    return {
        key: pubkey,
        run: (store) => store.replaceable(kinds.Mutelist, pubkey).pipe(map((event) => {
            if (!event)
                return undefined;
            const muted = getHiddenMutedThings(event);
            if (isHiddenTagsLocked(event) || !muted)
                return { locked: true };
            return { locked: false, ...muted };
        })),
    };
}
