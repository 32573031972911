import { getPubkeyFromDecodeResult } from "applesauce-core/helpers";
import { includeQuoteTags } from "./quote.js";
import { includeContentHashtags } from "./hashtags.js";
import { includeContentEmojiTags } from "./emojis.js";
import { getContentPointers } from "../../helpers/content.js";
import { ensureProfilePointerTag } from "../../helpers/common-tags.js";
/** Override the event content */
export function setContent(content) {
    return async (draft) => {
        return { ...draft, content };
    };
}
/** Encrypts the content to a pubkey */
export function setEncryptedContent(pubkey, content, method) {
    return async (draft, { signer }) => {
        if (!signer)
            throw new Error("Signer required for encrypted content");
        if (!signer[method])
            throw new Error(`Signer does not support ${method} encryption`);
        return { ...draft, content: await signer[method].encrypt(pubkey, content) };
    };
}
/** Replaces any `@npub` or bare npub mentions with nostr: prefix */
export function repairContentNostrLinks() {
    return (draft) => ({
        ...draft,
        content: draft.content.replaceAll(/(?<=^|\s)(?:@)?((?:npub|note|nprofile|nevent|naddr)1[qpzry9x8gf2tvdw0s3jn54khce6mua7l]{58})/gi, "nostr:$1"),
    });
}
/** "p" tag any pubkey mentioned in the content using nostr: links */
export function tagPubkeyMentionedInContent() {
    return (draft) => {
        let tags = Array.from(draft.tags);
        const mentions = getContentPointers(draft.content);
        for (const mention of mentions) {
            const pubkey = getPubkeyFromDecodeResult(mention);
            if (pubkey)
                tags = ensureProfilePointerTag(tags, mention.type === "nprofile" ? mention.data : { pubkey });
        }
        return { ...draft, tags };
    };
}
/** Sets the NIP-36 content-warning tag */
export function setContentWarning(warning) {
    return (draft) => {
        let tags = Array.from(draft.tags);
        // remove existing content warning
        tags = tags.filter((t) => t[0] !== "content-warning");
        if (typeof warning === "string")
            tags.push(["content-warning", warning]);
        else if (warning === true)
            tags.push(["content-warning"]);
        return { ...draft, tags };
    };
}
/** Create a set of operations for common text content */
export function createTextContentOperations(content, options) {
    return [
        // set text content
        setContent(content),
        // fix @ mentions
        repairContentNostrLinks(),
        // include "p" tags for pubkeys mentioned
        tagPubkeyMentionedInContent(),
        // include event "q" tags
        includeQuoteTags(),
        // include "t" tags for hashtags
        includeContentHashtags(),
        // include "emoji" tags
        options?.emojis && includeContentEmojiTags(options.emojis),
        // set "content-warning" tag
        options?.contentWarning !== undefined ? setContentWarning(options.contentWarning) : undefined,
    ].filter((o) => !!o);
}
