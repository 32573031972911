/** A signer that only implements getPublicKey and throws on ever other method */
export class ReadonlySigner {
    pubkey;
    nip04;
    nip44;
    constructor(pubkey) {
        this.pubkey = pubkey;
        this.nip04 = {
            encrypt: this.nip04Encrypt.bind(this),
            decrypt: this.nip04Decrypt.bind(this),
        };
        this.nip44 = {
            encrypt: this.nip44Encrypt.bind(this),
            decrypt: this.nip44Decrypt.bind(this),
        };
    }
    getPublicKey() {
        return this.pubkey;
    }
    getRelays() {
        return {};
    }
    signEvent() {
        throw new Error("Cant sign events with readonly");
    }
    nip04Encrypt() {
        throw new Error("Cant encrypt with readonly");
    }
    nip04Decrypt() {
        throw new Error("Cant decrypt with readonly");
    }
    nip44Encrypt() {
        throw new Error("Cant encrypt with readonly");
    }
    nip44Decrypt() {
        throw new Error("Cant decrypt with readonly");
    }
}
