import { getEventHash, nip19, verifyEvent } from "nostr-tools";
import { getPubkeyFromDecodeResult, isHexKey, isHex } from "applesauce-core/helpers";
import { createDefer } from "applesauce-core/promise";
/**
 * A Signer for [amber](https://github.com/greenart7c3/Amber) clipboard API
 * @see https://github.com/greenart7c3/Amber/blob/master/docs/web-apps.md
 */
export class AmberClipboardSigner {
    /** If the signer is supported on this platform */
    static SUPPORTED = `navigator` in globalThis &&
        navigator.userAgent.includes("Android") &&
        navigator.clipboard &&
        navigator.clipboard.readText;
    pendingRequest = null;
    pubkey;
    verifyEvent = verifyEvent;
    nip04;
    nip44;
    constructor() {
        document.addEventListener("visibilitychange", this.onVisibilityChange);
        this.nip04 = {
            encrypt: this.nip04Encrypt.bind(this),
            decrypt: this.nip04Decrypt.bind(this),
        };
        this.nip44 = {
            encrypt: this.nip44Encrypt.bind(this),
            decrypt: this.nip44Decrypt.bind(this),
        };
    }
    onVisibilityChange = () => {
        if (document.visibilityState === "visible") {
            if (!this.pendingRequest || !navigator.clipboard)
                return;
            // read the result from the clipboard
            setTimeout(() => {
                navigator.clipboard
                    .readText()
                    .then((result) => this.pendingRequest?.resolve(result))
                    .catch((e) => this.pendingRequest?.reject(e));
            }, 200);
        }
    };
    async intentRequest(intent) {
        this.rejectPending();
        const request = createDefer();
        window.open(intent, "_blank");
        // NOTE: wait 500ms before setting the pending request since the visibilitychange event fires as soon as window.open is called
        setTimeout(() => {
            this.pendingRequest = request;
        }, 500);
        const result = await request;
        if (result.length === 0)
            throw new Error("Empty clipboard");
        return result;
    }
    /** Reject the currently pending request */
    rejectPending() {
        if (this.pendingRequest) {
            this.pendingRequest.reject("Canceled");
            this.pendingRequest = null;
        }
    }
    /** Removes any event listeners created */
    destroy() {
        document.removeEventListener("visibilitychange", this.onVisibilityChange);
    }
    checkSupport() {
        if (!AmberClipboardSigner.SUPPORTED)
            throw new Error("Cant use Amber on non-Android device");
    }
    async getPublicKey() {
        this.checkSupport();
        if (this.pubkey)
            return this.pubkey;
        const result = await this.intentRequest(AmberClipboardSigner.createGetPublicKeyIntent());
        if (isHexKey(result)) {
            this.pubkey = result;
            return result;
        }
        else if (result.startsWith("npub") || result.startsWith("nprofile")) {
            const decode = nip19.decode(result);
            const pubkey = getPubkeyFromDecodeResult(decode);
            if (!pubkey)
                throw new Error("Expected npub from clipboard");
            this.pubkey = pubkey;
            return pubkey;
        }
        throw new Error("Expected clipboard to have pubkey");
    }
    async signEvent(draft) {
        this.checkSupport();
        const pubkey = draft.pubkey || this.pubkey;
        if (!pubkey)
            throw new Error("Unknown signer pubkey");
        const draftWithId = { ...draft, id: getEventHash({ ...draft, pubkey }) };
        const sig = await this.intentRequest(AmberClipboardSigner.createSignEventIntent(draftWithId));
        if (!isHex(sig))
            throw new Error("Expected hex signature");
        const event = { ...draftWithId, sig, pubkey };
        if (!this.verifyEvent(event))
            throw new Error("Invalid signature");
        return event;
    }
    // NIP-04
    async nip04Encrypt(pubkey, plaintext) {
        this.checkSupport();
        const data = await this.intentRequest(AmberClipboardSigner.createNip04EncryptIntent(pubkey, plaintext));
        return data;
    }
    async nip04Decrypt(pubkey, data) {
        this.checkSupport();
        const plaintext = await this.intentRequest(AmberClipboardSigner.createNip04DecryptIntent(pubkey, data));
        return plaintext;
    }
    // NIP-44
    async nip44Encrypt(pubkey, plaintext) {
        this.checkSupport();
        const data = await this.intentRequest(AmberClipboardSigner.createNip44EncryptIntent(pubkey, plaintext));
        return data;
    }
    async nip44Decrypt(pubkey, data) {
        this.checkSupport();
        const plaintext = await this.intentRequest(AmberClipboardSigner.createNip44DecryptIntent(pubkey, data));
        return plaintext;
    }
    // static methods
    static createGetPublicKeyIntent() {
        return `intent:#Intent;scheme=nostrsigner;S.compressionType=none;S.returnType=signature;S.type=get_public_key;end`;
    }
    static createSignEventIntent(draft) {
        return `intent:${encodeURIComponent(JSON.stringify(draft))}#Intent;scheme=nostrsigner;S.compressionType=none;S.returnType=signature;S.type=sign_event;end`;
    }
    static createNip04EncryptIntent(pubkey, plainText) {
        return `intent:${encodeURIComponent(plainText)}#Intent;scheme=nostrsigner;S.pubKey=${pubkey};S.compressionType=none;S.returnType=signature;S.type=nip04_encrypt;end`;
    }
    static createNip04DecryptIntent(pubkey, ciphertext) {
        return `intent:${encodeURIComponent(ciphertext)}#Intent;scheme=nostrsigner;S.pubKey=${pubkey};S.compressionType=none;S.returnType=signature;S.type=nip04_decrypt;end`;
    }
    static createNip44EncryptIntent(pubkey, plainText) {
        return `intent:${encodeURIComponent(plainText)}#Intent;scheme=nostrsigner;S.pubKey=${pubkey};S.compressionType=none;S.returnType=signature;S.type=nip44_encrypt;end`;
    }
    static createNip44DecryptIntent(pubkey, ciphertext) {
        return `intent:${encodeURIComponent(ciphertext)}#Intent;scheme=nostrsigner;S.pubKey=${pubkey};S.compressionType=none;S.returnType=signature;S.type=nip44_decrypt;end`;
    }
}
