import hash_sum from "hash-sum";
import { getReplaceableUID } from "../helpers/event.js";
/** Creates a Query that returns a single event or undefined */
export function SingleEventQuery(id) {
    return {
        key: id,
        run: (events) => events.event(id),
    };
}
/** Creates a Query that returns a multiple events in a map */
export function MultipleEventsQuery(ids) {
    return {
        key: ids.join(","),
        run: (events) => events.events(ids),
    };
}
/** Creates a Query returning the latest version of a replaceable event */
export function ReplaceableQuery(kind, pubkey, d) {
    return {
        key: getReplaceableUID(kind, pubkey, d),
        run: (events) => events.replaceable(kind, pubkey, d),
    };
}
/** Creates a Query that returns an array of sorted events matching the filters */
export function TimelineQuery(filters, keepOldVersions) {
    filters = Array.isArray(filters) ? filters : [filters];
    return {
        key: hash_sum(filters) + (keepOldVersions ? "-history" : ""),
        run: (events) => events.timeline(filters, keepOldVersions),
    };
}
/** Creates a Query that returns a directory of events by their UID */
export function ReplaceableSetQuery(pointers) {
    return {
        key: hash_sum(pointers),
        run: (events) => events.replaceableSet(pointers),
    };
}
