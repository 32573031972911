import { Expressions } from "applesauce-content/helpers";
import { nip19 } from "nostr-tools";
export function getContentPointers(content) {
    const mentions = content.matchAll(Expressions.nostrLink);
    const pointers = [];
    for (const [_, $1] of mentions) {
        try {
            const decode = nip19.decode($1);
            pointers.push(decode);
        }
        catch (error) { }
    }
    return pointers;
}
