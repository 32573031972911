/** Tests if a tag has at least two indexes, and optionally the value of the first */
export function isNameValueTag(tag, name) {
    return tag[0] !== undefined && tag[1] !== undefined && (name ? tag[0] === name : true);
}
/** Checks if tag is an "e" tag and has at least one value */
export function isETag(tag) {
    return isNameValueTag(tag, "e");
}
/** Checks if tag is an "p" tag and has at least one value */
export function isPTag(tag) {
    return isNameValueTag(tag, "p");
}
/** Checks if tag is an "r" tag and has at least one value */
export function isRTag(tag) {
    return isNameValueTag(tag, "r");
}
/** Checks if tag is an "d" tag and has at least one value */
export function isDTag(tag) {
    return isNameValueTag(tag, "d");
}
/** Checks if tag is an "a" tag and has at least one value */
export function isATag(tag) {
    return isNameValueTag(tag, "a");
}
/** Checks if tag is an "a" tag and has at least one value */
export function isTTag(tag) {
    return isNameValueTag(tag, "t");
}
/** Filter and transform tags */
export const processTags = (tags, ...fns) => {
    return fns.reduce((step, fn) => {
        const next = [];
        for (const value of step) {
            try {
                const result = fn(value);
                if (result === undefined)
                    continue; // value is undefined, ignore
                next.push(result);
            }
            catch (error) {
                // failed to process value, ignore
            }
        }
        return next;
    }, tags);
};
