import { kinds } from "nostr-tools";
import { GROUPS_LIST_KIND } from "./groups.js";
import { unixNow } from "./time.js";
import { isEvent } from "./event.js";
export const HiddenTagsSymbol = Symbol.for("hidden-tags");
/** Various event kinds that can have encrypted tags in their content and which encryption method they use */
export const EventEncryptionMethod = {
    // NIP-60 wallet
    37375: "nip44",
    // NIP-51 lists
    [kinds.BookmarkList]: "nip04",
    [kinds.InterestsList]: "nip04",
    [kinds.Mutelist]: "nip04",
    [kinds.CommunitiesList]: "nip04",
    [kinds.PublicChatsList]: "nip04",
    [kinds.SearchRelaysList]: "nip04",
    [GROUPS_LIST_KIND]: "nip04",
    // NIP-51 sets
    [kinds.Bookmarksets]: "nip04",
    [kinds.Relaysets]: "nip04",
    [kinds.Followsets]: "nip04",
    [kinds.Curationsets]: "nip04",
    [kinds.Interestsets]: "nip04",
};
/** Checks if an event can have hidden tags */
export function canHaveHiddenTags(kind) {
    return EventEncryptionMethod[kind] !== undefined;
}
/** Checks if an event has hidden tags */
export function hasHiddenTags(event) {
    return canHaveHiddenTags(event.kind) && event.content.length > 0;
}
/** Returns the hidden tags for an event if they are unlocked */
export function getHiddenTags(event) {
    return Reflect.get(event, HiddenTagsSymbol);
}
/** Checks if the hidden tags are locked */
export function isHiddenTagsLocked(event) {
    return hasHiddenTags(event) && getHiddenTags(event) === undefined;
}
/** Returns either nip04 or nip44 encryption method depending on list kind */
export function getListEncryptionMethods(kind, signer) {
    const method = EventEncryptionMethod[kind];
    const encryption = signer[method];
    if (!encryption)
        throw new Error(`Signer does not support ${method} encryption`);
    return encryption;
}
/**
 * Decrypts the private list
 * @param event The list event to decrypt
 * @param signer A signer to use to decrypt the tags
 * @param store An optional EventStore to notify about the update
 * @throws
 */
export async function unlockHiddenTags(event, signer, store) {
    if (!canHaveHiddenTags(event.kind))
        throw new Error("Event kind does not support hidden tags");
    const encryption = getListEncryptionMethods(event.kind, signer);
    const plaintext = await encryption.decrypt(event.pubkey, event.content);
    const parsed = JSON.parse(plaintext);
    if (!Array.isArray(parsed))
        throw new Error("Content is not an array of tags");
    // Convert array to tags array string[][]
    const tags = parsed.filter((t) => Array.isArray(t)).map((t) => t.map((v) => String(v)));
    Reflect.set(event, HiddenTagsSymbol, tags);
    if (store && isEvent(event))
        store.update(event);
    return event;
}
/**
 * Override the hidden tags in an event
 * @throws
 */
export async function overrideHiddenTags(event, hidden, signer) {
    if (!canHaveHiddenTags(event.kind))
        throw new Error("Event kind does not support hidden tags");
    const encryption = getListEncryptionMethods(event.kind, signer);
    const ciphertext = await encryption.encrypt(event.pubkey, JSON.stringify(hidden));
    return {
        kind: event.kind,
        content: ciphertext,
        created_at: unixNow(),
        tags: event.tags,
    };
}
