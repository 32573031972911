import { getOrComputeCachedValue } from "./cache.js";
/**
 * Parses file metadata tags into {@link FileMetadata}
 * @throws
 */
export function parseFileMetadataTags(tags) {
    const fields = {};
    let fallback = undefined;
    for (const [name, value] of tags) {
        switch (name) {
            case "fallback":
                fallback = fallback ? [...fallback, value] : [value];
                break;
            default:
                fields[name] = value;
                break;
        }
    }
    if (!fields.url)
        throw new Error("Missing required url in file metadata");
    const metadata = { url: fields.url, fallback };
    // parse size
    if (fields.size)
        metadata.size = parseInt(fields.size);
    // copy optional fields
    if (fields.m)
        metadata.type = fields.m;
    if (fields.x)
        metadata.sha256 = fields.x;
    if (fields.ox)
        metadata.originalSha256 = fields.ox;
    if (fields.dim)
        metadata.dimensions = fields.dim;
    if (fields.magnet)
        metadata.magnet = fields.magnet;
    if (fields.i)
        metadata.infohash = fields.i;
    if (fields.thumb)
        metadata.thumbnail = fields.thumb;
    if (fields.image)
        metadata.image = fields.image;
    if (fields.summary)
        metadata.summary = fields.summary;
    if (fields.alt)
        metadata.alt = fields.alt;
    if (fields.blurhash)
        metadata.blurhash = fields.blurhash;
    return metadata;
}
/**
 * Parses a imeta tag into a {@link FileMetadata}
 * @throws
 */
export function getFileMetadataFromImetaTag(tag) {
    const parts = tag.slice(1);
    const tags = [];
    for (const part of parts) {
        const match = part.match(/^(.+?)\s(.+)$/);
        if (match) {
            const [_, name, value] = match;
            tags.push([name, value]);
        }
    }
    return parseFileMetadataTags(tags);
}
export const MediaAttachmentsSymbol = Symbol.for("media-attachments");
/** Gets all the media attachments on an event */
export function getMediaAttachments(event) {
    return getOrComputeCachedValue(event, MediaAttachmentsSymbol, () => {
        return event.tags
            .filter((t) => t[0] === "imeta")
            .map((tag) => {
            try {
                return getFileMetadataFromImetaTag(tag);
            }
            catch (error) {
                // ignore invalid attachments
                return undefined;
            }
        })
            .filter((a) => !!a);
    });
}
/**
 * Gets {@link FileMetadata} for a NIP-94 kind 1063 event
 * @throws
 */
export function getFileMetadata(file) {
    return parseFileMetadataTags(file.tags);
}
/** Returns the last 64 length hex string in a URL */
export function getSha256FromURL(url) {
    if (typeof url === "string")
        url = new URL(url);
    const hashes = Array.from(url.pathname.matchAll(/[0-9a-f]{64}/gi));
    if (hashes.length > 0)
        return hashes[hashes.length - 1][0];
    return;
}
