import { kinds } from "nostr-tools";
import { map } from "rxjs/operators";
import { isHiddenTagsLocked } from "../helpers/index.js";
import { getBookmarks, getHiddenBookmarks } from "../helpers/bookmarks.js";
export function UserBookmarkQuery(pubkey) {
    return {
        key: pubkey,
        run: (store) => store.replaceable(kinds.Mutelist, pubkey).pipe(map((event) => event && getBookmarks(event))),
    };
}
export function UserHiddenBookmarkQuery(pubkey) {
    return {
        key: pubkey,
        run: (store) => store.replaceable(kinds.Mutelist, pubkey).pipe(map((event) => {
            if (!event)
                return undefined;
            const bookmarks = getHiddenBookmarks(event);
            if (isHiddenTagsLocked(event) || !bookmarks)
                return { locked: true };
            return { locked: false, ...bookmarks };
        })),
    };
}
