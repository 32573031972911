import { useMemo } from "react";
import { of } from "rxjs";
import { useObservableEagerState } from "observable-hooks";
import { useQueryStore } from "./use-query-store.js";
/**
 * Runs and subscribes to a query in the query store
 * @example
 * const events = useStoreQuery(TimelineQuery, [{kinds: [1]}])
 */
export function useStoreQuery(queryConstructor, args) {
    const store = useQueryStore();
    const observable = useMemo(() => {
        if (args)
            return store.createQuery(queryConstructor, ...args);
        else
            return of(undefined);
    }, [args, store]);
    return useObservableEagerState(observable);
}
