import { kinds } from "nostr-tools";
import { map } from "rxjs/operators";
import { isPTag, processTags } from "../helpers/tags.js";
import { getProfilePointerFromPTag } from "../helpers/pointers.js";
export function UserContactsQuery(pubkey) {
    return {
        key: pubkey,
        run: (store) => store
            .replaceable(kinds.Contacts, pubkey)
            .pipe(map((event) => event && processTags(event.tags.filter(isPTag), getProfilePointerFromPTag))),
    };
}
