import { finalize, tap } from "rxjs";
export function claimLatest(database) {
    return (source) => {
        let latest = undefined;
        return source.pipe(tap((event) => {
            // remove old claim
            if (latest)
                database.removeClaim(latest, source);
            // claim new event
            if (event)
                database.claimEvent(event, source);
            // update state
            latest = event;
        }), finalize(() => {
            // late claim
            if (latest)
                database.removeClaim(latest, source);
        }));
    };
}
