import { forwardRef, memo, useMemo } from "react";
import { Avatar, AvatarProps } from "@chakra-ui/react";
import { useAsync } from "react-use";
import styled from "@emotion/styled";
import { ProfileContent } from "applesauce-core/helpers";
import { useActiveAccount } from "applesauce-react/hooks";

import { getIdenticon } from "../../helpers/identicon";
import { safeUrl } from "../../helpers/parse";
import { getDisplayName } from "../../helpers/nostr/profile";
import useAppSettings from "../../hooks/use-user-app-settings";
import { buildImageProxyURL } from "../../helpers/image";
import UserDnsIdentityIcon from "./user-dns-identity-icon";
import useUserMuteList from "../../hooks/use-user-mute-list";
import useUserProfile from "../../hooks/use-user-profile";

export const UserIdenticon = memo(({ pubkey }: { pubkey: string }) => {
	const { value: identicon } = useAsync(() => getIdenticon(pubkey), [pubkey]);

	return identicon ? (
		<img
			src={`data:image/svg+xml;base64,${identicon}`}
			width="100%"
			style={{ borderRadius: "var(--chakra-radii-lg)" }}
		/>
	) : null;
});

const RESIZE_PROFILE_SIZE = 96;

export type UserAvatarProps = Omit<
	MetadataAvatarProps,
	"pubkey" | "metadata"
> & {
	pubkey: string;
	relay?: string;
};
export const UserAvatar = forwardRef<HTMLDivElement, UserAvatarProps>(
	({ pubkey, noProxy, relay, size, ...props }, ref) => {
		const profile = useUserProfile(pubkey, relay ? [relay] : undefined);
		const account = useActiveAccount();
		const muteList = useUserMuteList(account?.pubkey);

		const muted = muteList?.tags.some((t) => t[0] === "p" && t[1] === pubkey);

		return (
			<MetadataAvatar
				pubkey={pubkey}
				metadata={muted ? undefined : profile}
				noProxy={noProxy}
				ref={ref}
				size={size}
				{...props}
			>
				{size !== "xs" && (
					<UserDnsIdentityIcon
						pubkey={pubkey}
						position="absolute"
						right={-1}
						bottom={-1}
						bgColor="white"
						borderRadius="50%"
						boxSize="1em"
					/>
				)}
			</MetadataAvatar>
		);
	},
);
UserAvatar.displayName = "UserAvatar";

const SquareAvatar = styled(Avatar)`
  img {
    border-radius: var(--chakra-radii-lg);
  }
`;
const SquareAvatarWithBorder = styled(SquareAvatar)`
  img {
    border-width: 0.18rem;
    border-color: inherit;
    border-style: solid;
  }
`;

export type MetadataAvatarProps = Omit<AvatarProps, "src"> & {
	metadata?: ProfileContent;
	pubkey?: string;
	noProxy?: boolean;
	square?: boolean;
};
export const MetadataAvatar = forwardRef<HTMLDivElement, MetadataAvatarProps>(
	({ pubkey, metadata, noProxy, children, square = true, ...props }, ref) => {
		const { imageProxy, proxyUserMedia, hideUsernames } = useAppSettings();
		const account = useActiveAccount();
		const picture = useMemo(() => {
			if (hideUsernames && pubkey && pubkey !== account?.pubkey)
				return undefined;
			if (metadata?.picture) {
				const src = safeUrl(metadata?.picture);
				if (src) {
					const proxyURL = buildImageProxyURL(src, RESIZE_PROFILE_SIZE);
					if (proxyURL) return proxyURL;
				} else if (!noProxy && proxyUserMedia && pubkey) {
					const last4 = String(pubkey).slice(pubkey.length - 4, pubkey.length);
					return `https://media.nostr.band/thumbs/${last4}/${pubkey}-picture-64`;
				}
				return src;
			}
		}, [metadata?.picture, imageProxy, proxyUserMedia, hideUsernames, account]);

		const color = pubkey ? "#" + pubkey.slice(0, 6) : undefined;

		const AvatarComponent = square ? SquareAvatar : Avatar;

		return (
			<AvatarComponent
				ref={ref}
				src={picture}
				icon={pubkey ? <UserIdenticon pubkey={pubkey} /> : undefined}
				// overflow="hidden"
				title={getDisplayName(metadata, pubkey ?? "")}
				borderColor={undefined}
				borderStyle="none"
				{...props}
			>
				{children}
			</AvatarComponent>
		);
	},
);

export default memo(UserAvatar);
