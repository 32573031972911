import { createRxOneshotReq } from "rx-nostr";
import { BehaviorSubject, filter, map, Observable } from "rxjs";
import { logger } from "applesauce-core";
import { nanoid } from "nanoid";
import { unixNow } from "applesauce-core/helpers";
import { Loader } from "./loader.js";
/** A loader that can be used to load a timeline in chunks */
export class RelayTimelineLoader extends Loader {
    relay;
    filters;
    id = nanoid(8);
    loading$ = new BehaviorSubject(false);
    get loading() {
        return this.loading$.value;
    }
    /** current "until" timestamp */
    cursor = Infinity;
    /** if the timeline is complete */
    complete = false;
    log = logger.extend("RelayTimelineLoader");
    constructor(rxNostr, relay, filters, opts) {
        super((source) => new Observable((observer) => {
            return source
                .pipe(filter(() => !this.loading && !this.complete), map((limit) => {
                // build next batch filters
                return filters.map((filter) => ({
                    limit: limit || opts?.limit,
                    ...filter,
                    // limit curser to now
                    until: Math.min(unixNow(), this.cursor),
                }));
            }), 
            // ignore empty filters
            filter((filters) => filters.length > 0))
                .subscribe((filters) => {
                // make batch request
                let count = 0;
                const req = createRxOneshotReq({ filters, rxReqId: this.id });
                this.loading$.next(true);
                this.log(`Next batch starting at ${filters[0].until} limit ${filters[0].limit}`);
                rxNostr.use(req, { on: { relays: [relay] } }).subscribe({
                    next: (packet) => {
                        // update cursor when event is received
                        this.cursor = Math.min(packet.event.created_at - 1, this.cursor);
                        count++;
                        // forward packet
                        observer.next(packet);
                    },
                    error: (err) => observer.error(err),
                    complete: () => {
                        // set loading to false when batch completes
                        this.loading$.next(false);
                        // set complete the observable if 0 events where returned
                        if (count === 0) {
                            observer.complete();
                            this.log(`Got ${count} event, Complete`);
                        }
                        else {
                            this.log(`Finished batch, got ${count} events`);
                        }
                    },
                });
            });
        }));
        this.relay = relay;
        this.filters = filters;
        // create a unique logger for this instance
        this.log = this.log.extend(relay);
    }
}
