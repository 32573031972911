import { filter, map } from "rxjs";
import { removePreviouslyUsedRelays } from "../helpers/pointer.js";
export function distinctRelays(keyFn, timeout = 60_000) {
    return (source$) => {
        const cache = new Map();
        return source$.pipe(map((message) => removePreviouslyUsedRelays(message, keyFn, cache, timeout)), filter((message) => message !== null));
    };
}
export function distinctRelaysBatch(keyFn, timeout = 60_000) {
    return (source$) => {
        const cache = new Map();
        return source$.pipe(map((batch) => batch.map((m) => removePreviouslyUsedRelays(m, keyFn, cache, timeout)).filter((m) => m !== null)));
    };
}
