import { isObservable, Observable } from "rxjs";
/** Keeps retrying a value until the generator returns */
export function generatorSequence(createGenerator, shouldComplete = true) {
    return (source) => {
        return new Observable((observer) => {
            return source.subscribe((value) => {
                const generator = createGenerator(value);
                const nextSequence = (prevResults) => {
                    const p = generator.next(prevResults);
                    const handleResult = (result) => {
                        // generator complete, exit
                        if (result.done) {
                            if (shouldComplete)
                                observer.complete();
                            return;
                        }
                        const results = [];
                        if (isObservable(result.value)) {
                            result.value.subscribe({
                                next: (v) => {
                                    // track results and pass along values
                                    results.push(v);
                                    observer.next(v);
                                },
                                error: (err) => {
                                    observer.error(err);
                                },
                                complete: () => {
                                    // run next step
                                    nextSequence(results);
                                },
                            });
                        }
                        else {
                            results.push(result.value);
                            observer.next(result.value);
                            nextSequence(results);
                        }
                    };
                    // if its an async generator, wait for the promise
                    if (p instanceof Promise)
                        p.then(handleResult, (err) => {
                            observer.error(err);
                        });
                    else
                        handleResult(p);
                };
                // start running steps
                nextSequence();
            });
        });
    };
}
