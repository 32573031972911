import { kinds } from "nostr-tools";
import { map } from "rxjs/operators";
import { isETag, processTags } from "../helpers/tags.js";
import { getEventPointerFromETag } from "../helpers/pointers.js";
export function UserPinnedQuery(pubkey) {
    return {
        key: pubkey,
        run: (store) => store
            .replaceable(kinds.Pinlist, pubkey)
            .pipe(map((event) => event && processTags(event.tags.filter(isETag), getEventPointerFromETag))),
    };
}
