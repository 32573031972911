import { kinds } from "nostr-tools";
import { map } from "rxjs/operators";
import { getInboxes, getOutboxes } from "../helpers/mailboxes.js";
/** A query that gets and parses the inbox and outbox relays for a pubkey */
export function MailboxesQuery(pubkey) {
    return {
        key: pubkey,
        run: (events) => events.replaceable(kinds.RelayList, pubkey).pipe(map((event) => event && {
            inboxes: getInboxes(event),
            outboxes: getOutboxes(event),
        })),
    };
}
