import { kinds } from "nostr-tools";
import { filter, map } from "rxjs/operators";
import { getProfileContent, isValidProfile } from "../helpers/profile.js";
/** A query that gets and parses the kind 0 metadata for a pubkey */
export function ProfileQuery(pubkey) {
    return {
        key: pubkey,
        run: (events) => {
            return events.replaceable(kinds.Metadata, pubkey).pipe(filter(isValidProfile), map((event) => event && getProfileContent(event)));
        },
    };
}
