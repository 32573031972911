import { unixNow } from "applesauce-core/helpers";
export var IdentityStatus;
(function (IdentityStatus) {
    /** Got error when fetching identity document */
    IdentityStatus["Error"] = "error";
    /** Identity missing from document */
    IdentityStatus["Missing"] = "missing";
    /** Identity was found */
    IdentityStatus["Found"] = "found";
})(IdentityStatus || (IdentityStatus = {}));
/** Gets an Identity from the .well-known/nostr.json document */
export function getIdentityFromJson(name, domain, json, checked = unixNow()) {
    const common = { name, domain, checked };
    if (!json.names)
        return { ...common, status: IdentityStatus.Missing };
    const pubkey = json.names[name];
    if (!pubkey)
        return { ...common, status: IdentityStatus.Missing };
    const relays = json.relays?.[pubkey];
    const hasNip46 = !!json.nip46;
    const nip46Relays = json.nip46?.[pubkey];
    return { ...common, pubkey, relays, nip46Relays, hasNip46, status: IdentityStatus.Found };
}
/** Returns all Identifies in a json document */
export function getIdentitiesFromJson(domain, json, checked = unixNow()) {
    if (!json.names)
        return {};
    return Object.keys(json.names).reduce((dir, name) => {
        const address = `${name}@${domain}`;
        const identity = getIdentityFromJson(name, domain, json, checked);
        dir[address] = identity;
        return dir;
    }, {});
}
/** convert all keys in names, and relays to lower case */
export function normalizeIdentityJson(json) {
    if (json.names) {
        for (const [name, pubkey] of Object.entries(json.names)) {
            delete json.names[name];
            json.names[name.toLowerCase()] = pubkey;
        }
    }
    if (json.relays) {
        for (const [name, pubkey] of Object.entries(json.relays)) {
            delete json.relays[name];
            json.relays[name.toLowerCase()] = pubkey;
        }
    }
    return json;
}
