import { getNip10References, isPTag } from "applesauce-core/helpers";
import { ensureMarkedEventPointerTag, ensureProfilePointerTag } from "../../helpers/common-tags.js";
/** Includes NIP-10 reply tags */
export function includeNoteThreadingTags(parent) {
    return async (draft, ctx) => {
        let tags = Array.from(draft.tags);
        const pointer = { id: parent.id, author: parent.pubkey, kind: parent.kind };
        if (ctx.getEventRelayHint) {
            const hint = await ctx.getEventRelayHint(parent.id);
            if (hint)
                pointer.relays = [hint];
        }
        const refs = getNip10References(parent);
        const root = refs.root?.e ?? pointer;
        const reply = pointer;
        tags = ensureMarkedEventPointerTag(tags, root, "root");
        tags = ensureMarkedEventPointerTag(tags, reply, "reply");
        return { ...draft, tags };
    };
}
/** Copies "p" tags from parent event and adds new pubkey */
export function includeNoteThreadingNotifyTags(parent) {
    return async (draft, ctx) => {
        let tags = Array.from(draft.tags);
        // copy "p" tags from parent event that are not mentions
        for (const tag of parent.tags) {
            if (isPTag(tag) && tag[3] !== "mention")
                tags.push(tag);
        }
        // add new "p" tag
        const hint = await ctx.getPubkeyRelayHint?.(parent.pubkey);
        tags = ensureProfilePointerTag(tags, { pubkey: parent.pubkey, relays: hint ? [hint] : undefined });
        return { ...draft, tags };
    };
}
