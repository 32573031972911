import { AmberClipboardSigner } from "applesauce-signers/signers/amber-clipboard-signer";
import { BaseAccount } from "../account.js";
/** An account for the amber clipboard api */
export class AmberClipboardAccount extends BaseAccount {
    static type = "amber-clipboard";
    toJSON() {
        return super.saveCommonFields({
            signer: undefined,
        });
    }
    static fromJSON(json) {
        const account = new AmberClipboardAccount(json.pubkey, new AmberClipboardSigner());
        return super.loadCommonFields(account, json);
    }
}
