import { share, Subject } from "rxjs";
/** Base loader class */
export class Loader {
    subject = new Subject();
    observable;
    pipe;
    subscribe;
    constructor(transform) {
        this.observable = this.subject.pipe(transform, 
        // only create a single instance of the transformer
        share());
        // copy pipe function
        this.pipe = this.observable.pipe.bind(this.observable);
        this.subscribe = this.observable.subscribe.bind(this.observable);
    }
    next(value) {
        this.subject.next(value);
    }
    [Symbol.observable]() {
        return this.observable;
    }
}
