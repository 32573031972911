import { SerialPortSigner } from "applesauce-signers/signers/serial-port-signer";
import { BaseAccount } from "../account.js";
/** An account for SerialPortSigner */
export class SerialPortAccount extends BaseAccount {
    static type = "serial-port";
    async unlock() {
        try {
            const pubkey = await this.signer.getPublicKey();
            if (pubkey !== this.pubkey)
                throw new Error("Signer returned incorrect pubkey");
            return true;
        }
        catch (error) {
            return false;
        }
    }
    toJSON() {
        return super.saveCommonFields({ signer: undefined });
    }
    static fromJSON(json) {
        const signer = new SerialPortSigner();
        const account = new SerialPortAccount(json.pubkey, signer);
        return super.loadCommonFields(account, json);
    }
}
