import { ExtensionSigner } from "applesauce-signers/signers/extension-signer";
import { BaseAccount } from "../account.js";
export class ExtensionAccount extends BaseAccount {
    signer;
    static type = "extension";
    constructor(pubkey, signer) {
        super(pubkey, signer || new ExtensionSigner());
        this.signer = signer;
    }
    toJSON() {
        return super.saveCommonFields({
            signer: undefined,
        });
    }
    static fromJSON(json) {
        const account = new ExtensionAccount(json.pubkey, new ExtensionSigner());
        return super.loadCommonFields(account, json);
    }
}
