import { nanoid } from "nanoid";
import { BehaviorSubject } from "rxjs";
function wrapInSignal(promise, signal) {
    return new Promise((res, rej) => {
        signal.throwIfAborted();
        let done = false;
        // reject promise if abort signal is triggered
        signal.addEventListener("abort", () => {
            if (!done)
                rej(signal.reason || undefined);
            done = true;
        });
        return promise.then((v) => {
            if (!done)
                res(v);
            done = true;
        }, (err) => {
            if (!done)
                rej(err);
            done = true;
        });
    });
}
export class SignerMismatchError extends Error {
}
export class BaseAccount {
    pubkey;
    signer;
    id = nanoid(8);
    get type() {
        const cls = Reflect.getPrototypeOf(this).constructor;
        return cls.type;
    }
    /** Disable request queueing */
    disableQueue;
    metadata$ = new BehaviorSubject(undefined);
    get metadata() {
        return this.metadata$.value;
    }
    set metadata(metadata) {
        this.metadata$.next(metadata);
    }
    // encryption interfaces
    nip04;
    nip44;
    constructor(pubkey, signer) {
        this.pubkey = pubkey;
        this.signer = signer;
        // setup encryption interfaces to check if account is locked
        if (this.signer.nip04) {
            this.nip04 = {
                encrypt: (pubkey, plaintext) => {
                    return this.waitForLock(() => this.signer.nip04.encrypt(pubkey, plaintext));
                },
                decrypt: (pubkey, plaintext) => {
                    return this.waitForLock(() => this.signer.nip04.decrypt(pubkey, plaintext));
                },
            };
        }
        if (this.signer.nip44) {
            this.nip44 = {
                encrypt: (pubkey, plaintext) => {
                    return this.waitForLock(() => this.signer.nip44.encrypt(pubkey, plaintext));
                },
                decrypt: (pubkey, plaintext) => {
                    return this.waitForLock(() => this.signer.nip44.decrypt(pubkey, plaintext));
                },
            };
        }
    }
    // This should be overwritten by a sub class
    toJSON() {
        throw new Error("Not implemented");
    }
    /** Adds the common fields to the serialized output of a toJSON method */
    saveCommonFields(json) {
        return { ...json, id: this.id, pubkey: this.pubkey, metadata: this.metadata, type: this.type };
    }
    /** Sets an accounts id and metadata. NOTE: This should only be used in fromJSON methods */
    static loadCommonFields(account, json) {
        if (json.id)
            account.id = json.id;
        if (json.metadata)
            account.metadata = json.metadata;
        return account;
    }
    /** Gets the pubkey from the signer */
    getPublicKey() {
        const result = this.signer.getPublicKey();
        if (result instanceof Promise)
            return result.then((pubkey) => {
                if (this.pubkey !== pubkey)
                    throw new SignerMismatchError("Account signer mismatch");
                return pubkey;
            });
        else {
            if (this.pubkey !== result)
                throw new SignerMismatchError("Account signer mismatch");
            return result;
        }
    }
    /** sign the event and make sure its signed with the correct pubkey */
    signEvent(template) {
        if (!Reflect.has(template, "pubkey"))
            Reflect.set(template, "pubkey", this.pubkey);
        return this.waitForLock(() => {
            const result = this.signer.signEvent(template);
            if (result instanceof Promise)
                return result.then((signed) => {
                    if (signed.pubkey !== this.pubkey)
                        throw new SignerMismatchError("Signer signed with wrong pubkey");
                    return signed;
                });
            else {
                if (result.pubkey !== this.pubkey)
                    throw new SignerMismatchError("Signer signed with wrong pubkey");
                return result;
            }
        });
    }
    /** Aborts all pending requests in the queue */
    abortQueue(reason) {
        if (this.abort)
            this.abort.abort(reason);
    }
    /** internal queue */
    queueLength = 0;
    lock = null;
    abort = null;
    reduceQueue() {
        // shorten the queue
        this.queueLength--;
        // if this was the last request, remove the lock
        if (this.queueLength === 0) {
            this.lock = null;
            this.abort = null;
        }
    }
    waitForLock(fn) {
        if (this.disableQueue)
            return fn();
        // if there is already a pending request, wait for it
        if (this.lock && this.abort) {
            // create a new promise that runs after the lock
            const p = wrapInSignal(this.lock.then(() => {
                // if the abort signal is triggered, don't call the signer
                this.abort?.signal.throwIfAborted();
                return fn();
            }), this.abort.signal);
            // set the lock the new promise that ignores errors
            this.lock = p.catch(() => { }).finally(this.reduceQueue.bind(this));
            this.queueLength++;
            return p;
        }
        else {
            const result = fn();
            // if the result is async, set the new lock
            if (result instanceof Promise) {
                this.abort = new AbortController();
                const p = wrapInSignal(result, this.abort.signal);
                // set the lock the new promise that ignores errors
                this.lock = p.catch(() => { }).finally(this.reduceQueue.bind(this));
                this.queueLength = 1;
            }
            return result;
        }
    }
}
