import { unixNow } from "applesauce-core/helpers";
import { isParameterizedReplaceableKind } from "nostr-tools/kinds";
import { includeClientTag } from "./operations/event/client.js";
import { CommentBlueprint } from "./blueprints/comment.js";
import { NoteBlueprint } from "./blueprints/note.js";
import { ReactionBlueprint } from "./blueprints/reaction.js";
import { DeleteBlueprint } from "./blueprints/delete.js";
import { NoteReplyBlueprint } from "./blueprints/reply.js";
import { ShareBlueprint } from "./blueprints/share.js";
import { includeReplaceableIdentifier, modifyHiddenTags, modifyPublicTags } from "./operations/event/index.js";
export class EventFactory {
    context;
    constructor(context = {}) {
        this.context = context;
    }
    static async runProcess(template, context, ...operations) {
        let draft = { content: "", created_at: unixNow(), tags: [], ...template };
        // make sure parameterized replaceable events have "d" tags
        if (isParameterizedReplaceableKind(draft.kind))
            draft = await includeReplaceableIdentifier()(draft, context);
        // run operations
        for (const operation of operations) {
            if (operation)
                draft = await operation(draft, context);
        }
        // add client tag
        if (context.client) {
            draft = await includeClientTag(context.client.name, context.client.address)(draft, context);
        }
        return draft;
    }
    /** Process an event template with operations */
    async process(template, ...operations) {
        return await EventFactory.runProcess(template, this.context, ...operations);
    }
    /** Create an event from a blueprint */
    async create(blueprint, ...args) {
        return await blueprint(...args)(this.context);
    }
    /** Modify an existing event with operations and updated the created_at */
    async modify(draft, ...operations) {
        draft = { ...draft, created_at: unixNow() };
        // Remove old fields from signed nostr event
        Reflect.deleteProperty(draft, "id");
        Reflect.deleteProperty(draft, "sig");
        Reflect.deleteProperty(draft, "pubkey");
        return await EventFactory.runProcess(draft, this.context, ...operations);
    }
    /** Modify a lists public and hidden tags and updated the created_at */
    async modifyTags(event, tagOperations, eventOperations) {
        let publicTagOperations = [];
        let hiddenTagOperations = [];
        let eventOperationsArr = [];
        // normalize tag operation arg
        if (tagOperations === undefined)
            publicTagOperations = hiddenTagOperations = [];
        else if (Array.isArray(tagOperations))
            publicTagOperations = tagOperations;
        else if (typeof tagOperations === "function")
            publicTagOperations = [tagOperations];
        else {
            if (typeof tagOperations.public === "function")
                publicTagOperations = [tagOperations.public];
            else if (tagOperations.public)
                publicTagOperations = tagOperations.public;
            if (typeof tagOperations.hidden === "function")
                hiddenTagOperations = [tagOperations.hidden];
            else if (tagOperations.hidden)
                hiddenTagOperations = tagOperations.hidden;
        }
        // normalize event operation arg
        if (eventOperations === undefined)
            eventOperationsArr = [];
        else if (typeof eventOperations === "function")
            eventOperationsArr = [eventOperations];
        else if (Array.isArray(eventOperations))
            eventOperationsArr = eventOperations.filter((e) => !!e);
        // modify event
        return await this.modify(event, publicTagOperations.length > 0 ? modifyPublicTags(...publicTagOperations) : undefined, hiddenTagOperations.length > 0 ? modifyHiddenTags(...hiddenTagOperations) : undefined, ...eventOperationsArr);
    }
    /** Attaches the signers pubkey to an event template */
    async stamp(draft) {
        if (!this.context.signer)
            throw new Error("Missing signer");
        // Remove old fields from signed nostr event
        Reflect.deleteProperty(draft, "id");
        Reflect.deleteProperty(draft, "sig");
        return { ...draft, pubkey: await this.context.signer.getPublicKey() };
    }
    // Helpers
    /** Create a NIP-22 comment */
    comment(...args) {
        return this.create(CommentBlueprint, ...args);
    }
    /** Creates a short text note */
    note(...args) {
        return this.create(NoteBlueprint, ...args);
    }
    /** Creates a short text note reply */
    noteReply(...args) {
        return this.create(NoteReplyBlueprint, ...args);
    }
    /** Creates a reaction event */
    reaction(...args) {
        return this.create(ReactionBlueprint, ...args);
    }
    /** Creates a delete event */
    delete(...args) {
        return this.create(DeleteBlueprint, ...args);
    }
    /** Creates a share event */
    share(...args) {
        return this.create(ShareBlueprint, ...args);
    }
}
