import { BehaviorSubject, combineLatest, connect, merge, tap } from "rxjs";
import { logger } from "applesauce-core";
import { mergeFilters } from "applesauce-core/helpers";
import { nanoid } from "nanoid";
import { RelayTimelineLoader } from "./relay-timeline-loader.js";
import { Loader } from "./loader.js";
import { CacheTimelineLoader } from "./cache-timeline-loader.js";
/** A multi-relay timeline loader that can be used to load a timeline from multiple relays */
export class TimelineLoader extends Loader {
    id = nanoid(8);
    loading$ = new BehaviorSubject(false);
    get loading() {
        return this.loading$.value;
    }
    requests;
    log = logger.extend("TimelineLoader");
    cache;
    loaders;
    constructor(rxNostr, requests, opts) {
        const loaders = new Map();
        // create cache loader
        const cache = opts?.cacheRequest
            ? new CacheTimelineLoader(opts.cacheRequest, [mergeFilters(...Object.values(requests).flat())], opts)
            : undefined;
        // create loaders
        for (const [relay, filters] of Object.entries(requests)) {
            loaders.set(relay, new RelayTimelineLoader(rxNostr, relay, filters, opts));
        }
        const allLoaders = cache ? [cache, ...loaders.values()] : Array.from(loaders.values());
        super((source) => {
            // observable that triggers the loaders based on cursor
            const trigger$ = source.pipe(tap((cursor) => {
                for (const loader of allLoaders) {
                    // load the next page if cursor is past loader cursor
                    if (!cursor || !Number.isFinite(cursor) || cursor <= loader.cursor)
                        loader.next();
                }
            }));
            // observable that handles updating the loading state
            const loading$ = combineLatest(allLoaders.map((l) => l.loading$)).pipe(
            // set loading to true as long as one loader is still loading
            tap((loading) => this.loading$.next(loading.some((v) => v === true))));
            // observable that merges all the outputs of the loaders
            const events$ = merge(...allLoaders.map((l) => l.observable));
            // subscribe to all observables but only return the results of events$
            return merge(trigger$, loading$, events$).pipe(connect((_shared$) => events$));
        });
        this.requests = requests;
        this.cache = cache;
        this.loaders = loaders;
        this.log = this.log.extend(this.id);
    }
    static simpleFilterMap(relays, filters) {
        return relays.reduce((map, relay) => ({ ...map, [relay]: filters }), {});
    }
}
