import { createIcon } from "@chakra-ui/icons";

const MoneroWhite = createIcon({
	displayName: "MoneroWhite",
	viewBox: "0 0 48 48",
	path: [
		<>
			<radialGradient
				key="WB2PwxTQsB8lfLfbsFSI4a_3s5uJPNeoda4_gr1"
				id="WB2PwxTQsB8lfLfbsFSI4a_3s5uJPNeoda4_gr1"
				cx="23.977"
				cy="24.322"
				r="19.904"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0" stopColor="#fff" />
				<stop offset=".679" stopColor="#f2f2f2" />
				<stop offset="1" stopColor="#ccc" />
			</radialGradient>
			<path
				key="WB2PwxTQsB8lfLfbsFSI4a_3s5uJPNeoda4"
				fill="black"
				d="M43.285,30H37V14.378c0-0.445-0.539-0.669-0.854-0.354L24,26.171L11.854,14.025	C11.539,13.71,11,13.933,11,14.378V30H4.797c0.429,1.407,1.015,2.743,1.726,4H14.5c0.276,0,0.5-0.224,0.5-0.5V22.829l8.646,8.646	c0.195,0.195,0.512,0.195,0.707,0L33,22.829V33.5c0,0.276,0.224,0.5,0.5,0.5h8.06C42.271,32.743,42.856,31.407,43.285,30z"
			/>
			<linearGradient
				key="WB2PwxTQsB8lfLfbsFSI4b_3s5uJPNeoda4_gr2"
				id="WB2PwxTQsB8lfLfbsFSI4b_3s5uJPNeoda4_gr2"
				x1="15.558"
				x2="36.877"
				y1="23.452"
				y2="49.75"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0" stopColor="#889097" />
				<stop offset="1" stopColor="#64717c" />
			</linearGradient>
			<path
				key="WB2PwxTQsB8lfLfbsFSI4b_3s5uJPNeoda4"
				fill="white"
				d="M33.5,34c-0.276,0-0.5-0.224-0.5-0.5V22.829l-8.646,8.646c-0.195,0.195-0.512,0.195-0.707,0	L15,22.829V33.5c0,0.276-0.224,0.5-0.5,0.5H6.522c3.454,6.111,9.999,10.244,17.519,10.244S38.106,40.111,41.56,34H33.5z"
			/>
			<linearGradient
				key="WB2PwxTQsB8lfLfbsFSI4c_3s5uJPNeoda4_gr3"
				id="WB2PwxTQsB8lfLfbsFSI4c_3s5uJPNeoda4_gr3"
				x1="13.641"
				x2="30.62"
				y1="5.9"
				y2="34.751"
				gradientUnits="userSpaceOnUse"
			>
				<stop offset="0" stopColor="#fc7d5b" />
				<stop offset=".06" stopColor="#f8734f" />
				<stop offset=".18" stopColor="#f3653d" />
				<stop offset=".326" stopColor="#f05b31" />
				<stop offset=".523" stopColor="#ee552a" />
				<stop offset="1" stopColor="#ed5328" />
			</linearGradient>
			<path
				key="WB2PwxTQsB8lfLfbsFSI4c_3s5uJPNeoda4"
				fill="white"
				d="M11,30V14.378c0-0.445,0.539-0.669,0.854-0.354L24,26.171l12.146-12.146	C36.461,13.71,37,13.933,37,14.378V30h6.285c0.568-1.86,0.878-3.832,0.878-5.878C44.163,13.01,35.153,4,24.041,4	S3.919,13.01,3.919,24.122c0,2.046,0.31,4.018,0.878,5.878H11z"
			/>
		</>,
	],
	defaultProps: { boxSize: 4 },
});

export default MoneroWhite;
